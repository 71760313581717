@import "../helpers/_variables";

.search-results {

}

.search-result {
    margin: 0;
    padding: 1rem 2rem 1.2rem 2rem;
    position: relative;
    text-decoration: none;
    overflow: hidden;
    color: $cl-body;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(238, 238, 238);
    transition: background 0.16s ease-in-out 0s;

    &:hover,
    &:focus,
    &:active {
        background: rgb(238, 238, 238);

        .search-result_descr {
            text-decoration: none;
        }
    }
}

.search-result_teaser {
    margin: 0.6rem 0;
    flex: 0 0 15rem;
    overflow: hidden;
    background: url('') no-repeat 50% 50% transparent;
    background-size: cover;

    @include responsive-ratio(600, 400, true)
}

.search-result_content {
    max-width: 70rem;
    margin: 0.6rem 0;
    flex: 1 1 30rem;
}

.search-result_title {
    margin: -0.2rem 0 0.4em 0;
    padding: 0 2rem 0 0;
    font-size: 2.4rem;
    font-weight: normal;
    color: inherit;
}

.search-result_descr {
    margin: 0 0 1.5rem 0;
    padding: 0 2rem 0 0;
    font-size: 15px;
    text-decoration: none;
}

.search-result_readmore {
    margin: 0;
    font-weight: bold;
    display: flex;
    color: #222;
    align-items: center;
}

.vz-link-icon {
    background: linear-gradient(270deg, rgb(244, 140, 0) 0%, rgb(244, 140, 0) 25.76%, rgb(230, 0, 0) 77.04%, rgb(230, 0, 0) 100%);
    border-radius: 30px 30px 15px;
    transition: all 0.16s ease-in-out 0s;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    svg {
        position: relative;
        left: 2px;

        path { fill: #fff; }
    }
}

.search-results_more {
    width: 100%;
    margin: 2rem 0 0 0;
    padding: 0 2rem;
    display: block;
    font-weight: 600;
    /*line-height: 1.2;
    text-transform: uppercase;*/
    text-align: left;

    /*.btn {
        font-family: AvertaStdCY-SemiBold, sans-serif;
        font-size: 18px;
        color: rgb(34, 34, 34);
        cursor: pointer;
        vertical-align: middle;
        border-radius: 23px 50% 11px 11px 23px 50%;
        padding: 0.75em 1.5em;
        background: rgb(255, 255, 255);

        &:hover,
        &:focus {
            color: #fff;
            background-color: $cl-primary-1;
        }
    }*/
}


.search-box-container {
    padding: 2rem;
    background: rgb(238, 238, 238);

    p {
        font-size: 11px;
        margin-bottom: 1em;
    }
}