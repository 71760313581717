@import "../helpers/_variables";


.page-aside {
    flex: 0 0 31rem;
    
    .main--page & {
        display: none;
    }

    
    .page-aside_title {
        margin: 1em 0 1em 0;
        padding: 0;
        font-size: 2rem;
        font-weight: 500;
    }

}

.page-aside--nav {
    margin: 0 1.4vw 0 0; // Fallback iOS 6 & 7
    margin: 0 calc(0.7vh + 0.7vw) 0 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    z-index: 40;
}
