@import "../helpers/_variables";


// =================================
//   only screen and (xxx-width: 768px)
// =================================

$fs-heading-1: round(($fs-heading-1 / 1.2) * 100) / 100;
$fs-heading-2: round(($fs-heading-2 / 1.2) * 100) / 100;
$fs-heading-3: round(($fs-heading-3 / 1.1) * 100) / 100;
$fs-heading-4: round(($fs-heading-4 / 1.08) * 100) / 100;

h1,
.h1 {
    font-size: $fs-heading-1;
}

h2,
.h2 {
    font-size: $fs-heading-2;
}

h3,
.h3 {
    font-size: $fs-heading-3;
}

h4,
.h4 {
    font-size: $fs-heading-4;
}

.nav-switcher {
    padding-right: 5rem;
}

.nav-list_url {
    font-size: 2rem;
    padding: 1rem 0;
}

.breadcrumbs {
    font-size: 1.2rem;
}


.flickity-prev-next-button {
    width: 3.2rem;
    height: 4rem;

    &.previous {
        margin-left: -1.6rem;

        .ve-homeview & {
            margin-left: initial;
        }
    }

    &.next {
        margin-right: -1.6rem;

        .ve-homeview & {
            margin-right: initial;
        }
    }
}

.flickity-page-dots {
    display: none;
}

.f-carousel {

    h1 {
        font-size: 2.6rem;

        .ve-homeview & {
            font-size: 2.2rem;
        }
    }

    p {
        font-size: initial;

        .ve-homeview & { 
            font-size: 1.4rem;
        }
    }

    .btn {
        font-size: 1.6rem;
    }
}

.f-carousel_cell-container {

    .ve-homeview & {
        padding: 0;
        justify-content: center;
        align-items: center;
    }
}

.f-carousel_cell-content {


    .ve-homeview & {
        min-width: 12rem;
        max-width: 80%;
        padding: 1.8rem 2rem;
    }
}


.search-result {
    padding: 1rem 0 1.2rem 1rem;
}

.container--article {

    .has-teaser--true & {
        margin-top: -2rem;
    }
}

.container--home {

    > p {
        font-size: 1.6rem;
    }
}

.content-home-wrapper {

    h2 {
        font-size: 3rem;
        text-transform: uppercase;
    }

    h3 {
        font-size: 2.2rem;
        text-transform: uppercase;

    }
}

.f-col {
    max-width: 100%;
}




.quote {
    width: 100%;
    padding: 2rem;
}

.quote-container:after {
    width: 100%;
    left: 0;
    background-image: linear-gradient(to right, #373737, rgba(55,55,55,0.75));
}

.quote__text {
    padding: 4rem 2rem 2rem;
    line-height: 1.75;
}

.quote__text:before, .quote__text:after {
    width: 35px;
    height: 35px;
}

.quote-img {
    height: 100%;
    margin: 0;
    width: 100%;
    background-size: cover;
    background-position: left;
}



.home-block {
    padding: 2rem 0;
}

.summary {
    padding-bottom: 3rem;

    &__title,
    &__info {
        padding: 0 1.5rem;

        .iframe-video {
            top: 54%;
        }

        img {
            margin-top: 2rem;
        }
    }

    h2 {
        margin-top: -3.5rem;
        font-size: 3rem;
        z-index: 10;
        position: relative;
        top: -12px;
        left: -1px;
    }

    p {
        margin: 2em 0 4em;
    }


    &-visual {
        max-height: 40vw;
    }
}

.footer {
    padding: 0;
}

.highlights .hl {
    width: 100%;
    max-width: 100%;

    h2 {
        left: 2rem;
        font-size: 2.4rem;
    }
}

.footer-top {
    flex-direction: column;
}

.footer-section {
    width: 100%;
    max-width: 100%;
}

.kpi-list li {
    margin: 0 0 2rem;
    padding: 1rem;
}

.kpi__icon {
    width: 50%;
    padding-right: 2rem;
}

.summary.noVisual {
    padding-top: 4rem;
}

.summary.noVisual p {
    margin: 0 0 4em;
}