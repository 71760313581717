@import "../helpers/_variables";

/* ==========================================================================  
  Zichtbaarheid in devices

  Alle weergaven      :  geen class toevoegen
  Alleen website tonen  :  sh-website
  Afwijkende weergave:
    Tablet  (portrait)  :  toevoegen “sh-tablet-p”
    Tablet  (landscape)  :  toevoegen “sh-tablet-l”
    Phone  (portrait)  :  toevoegen “sh-mobile-p”
    Phone  (landscape)  :  toevoegen “sh-mobile-l”
    Niet op website    :  toevoegen “sh-website-no”

   ========================================================================== */

/* Test in preview om te checken of zichtbaarheid werkt */

.sh-website-no  {display:none;}
.preview-tablet-p .sh-tablet-p   {display:block !important;}
.preview-tablet-p .sh-tablet-l   {display:none !important;}
.preview-tablet-p .sh-mobile-p   {display:none !important;}
.preview-tablet-p .sh-mobile-l   {display:none !important;}
.preview-tablet-p .sh-website-no {display:none !important;}

.preview-tablet-l .sh-tablet-p   {display:none !important;}
.preview-tablet-l .sh-tablet-l   {display:block !important;}
.preview-tablet-l .sh-mobile-p   {display:none !important;}
.preview-tablet-l .sh-mobile-l   {display:none !important;}

.preview-mobile-p .sh-tablet-p   {display:none !important;}
.preview-mobile-p .sh-tablet-l   {display:none !important;}
.preview-mobile-p .sh-mobile-p   {display:block !important;}
.preview-mobile-p .sh-mobile-l   {display:none !important;}

.preview-mobile-l .sh-tablet-p   {display:none !important;}
.preview-mobile-l .sh-tablet-l   {display:none !important;}
.preview-mobile-l .sh-mobile-p   {display:none !important;}
.preview-mobile-l .sh-mobile-l   {display:block !important;}
