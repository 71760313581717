@import "../helpers/_variables";


.social-list_title {
    margin: 1rem 0 0 0;
}

.social-list {
    margin: 1rem 0 0 0;
    padding: 0;
    list-style: none;
    color: #fff;

    a {
        width: 4rem;
        height: 4rem;
        color: inherit;
        text-decoration: none;
        display: inline-block;
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        .footer & {
            border: 0;
            text-decoration: none;
        }
    }
}

.social-list_item {
    margin: 0.2em 0.3em 0 0;
    display: inline-block;
    transition: opacity 0.16s ease-in 0s;
    border-radius: 12px;

    &:focus,
    &:hover {
        opacity: 0.6;
    }

    &.sl-linkedin {
        background: rgb(0, 120, 187);
    }

    &.sl-twitter {
        background: rgb(0, 170, 239);
    }

    &.sl-facebook {
        background: rgb(60, 86, 154);
    }
}

.social-list_url {
    font-weight: bold;
}

.social-list_descr {
    font-weight: 400;
}