@import "../helpers/_variables";

// =================================
//   Hierachicalmenu
// =================================

.hr-list {
    padding: .5em 0 .2em 0;
    margin: 0;
    z-index: 100;
    list-style: none;
    color: #222;
    font-weight: 400;
    font-size: 1.6rem;

    &.state--hidden {
        display: none;
    }

    &.state--open,
    &.state--show {
        display: block;
    }
}

.hr-list--0 {
    padding: 0;

    > .hr-list_item {
        padding: 0;
    }
    
    ul {
        display:none;
    }
}



.hr-list_item {
    position: relative;
    padding-left: 1em;
    margin-bottom: .8em;
}



.hr-list_url {
    word-wrap: break-word;
    word-break: break-word;
    color: inherit;
    // For alignment with icons
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    &:focus,
    &:hover {
        color: $cl-primary-1;

        .svg-icon {
            transform: translateX(0.2em);
        }
    }

    &.state--selected {
        color: $cl-primary-1;
        cursor: default;

        &:focus,
        &:hover {
            color: $cl-primary-1;

            .svg-icon {
                transform: translateX(0em);
            }
        }
    }

    .svg-icon {
        top: 5px;
        position: relative;
        width: 0.7em;
        height: 0.9em;
        margin: 0.2em 0.3em 0 0;
        flex: 0 0 0.7em;
        transition: all ease 0.2s;
        transform: translateX(0em) translateZ(0px);
    }

    span {
        flex: 1 1 auto;
    }
}

.hr-list li.state--selected > ul {
    display:block;
}

.hr-list_url--0 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 700;
}

.hr-list_url--1 {
    font-size: 1.5rem;
    font-weight: 400;

    .svg-icon {
        width: 0.8rem;
        height: 1.3rem;
    }
}

.hr-list_url--2 {
    font-size: 75%;
    font-weight: normal;
    color: #000;
}
