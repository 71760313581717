@import "../helpers/_variables";

// =============================================================================
//   Only remove basic btn styling when you'll also change cookierbar styling,
//   otherwise leave this code alone. Attribute selector is easy to override.
// =============================================================================

[class^="btn"] {
    @include appearance();
    border: 0;
    color: inherit;
    cursor: pointer;
    background: none;

    &:hover,
    &:focus {
        outline: 0; // When using 0, do not forget focus styling
    }
}

// General class for buttons used for devices.
// Only visible on (<=960px)
.btn-devices {
    display: none;
}

// Primary navigation
.btn-menu {
    margin: 0;
    padding: .4em;
    z-index: 100;
}

.btn-close {
    width: 50px;
    height: 50px;
    position: absolute;

    &:hover,
    &:focus {
        border-color: currentColor;
    }
}

// Article navigation (hierarchical menu)
.btn-article {
    padding: 0.94em 0.8em;
    position: absolute;
    background-color: #FFF;
}

.url-span,
.txt-btn {
    display: inline-block;
    vertical-align: middle;
}

.btn-corp {
    min-height: 50px;
    transition: all 0.16s ease-in-out 0s;
    border-radius: 30px 15px 15px 30px;
    background: linear-gradient(270deg, #f48c00 0%, #f48c00 24.65%, #e60000 74.74%, #e60000 100%);
    padding: 13px 20px 13px 26px;
    color: #fff;
    font-weight: 600;

    &:hover,
    &:focus {
        opacity: .8;
    }
}

// Dynamic article buttons
.f-btn {
    margin: 1em 0 3em;
    display: inline-block;
}

.f-btn-link {
    min-height: 50px;
    transition: all 0.16s ease-in-out 0s;
    border-radius: 30px 15px 15px 30px;
    background: linear-gradient(270deg, #f48c00 0%, #f48c00 24.65%, #e60000 74.74%, #e60000 100%);
    padding: 13px 20px 13px 26px;
    color: #fff;
    font-weight: 600;

    &:hover,
    &:focus {
        opacity: .8;
    }
}

// Overlay that shows/hides when menu's are opened/closed.
.fade-overlay {
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0);
    transition: all ease 0.3s;

    &--in {
        background-color: rgba(0, 0, 0, 0.7);
    }

    &--out {
        background-color: rgba(0, 0, 0, 0);
    }
}

.vz-btn {
    display: inline-block;
    background-image: linear-gradient(90deg, rgb(230, 0, 0), rgb(244, 140, 0));
    padding: 2px;
    border-radius: 23px 11px 11px 23px;
    font-size: 16px;
    
    span {
        color: #fff;
        vertical-align: middle;
        padding: 0.75em 1.5em;
        display: inline-block;
    }
}