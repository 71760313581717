@import "../helpers/_variables";

// =================================
//   Specific sub home styling
// =================================

$gradient: linear-gradient(270deg, #EA811E 0%, #ea811e 24.65%, #e60000 74.74%, #e60000 100%);

.home-block {
    padding: 2rem 0;
}

.summary {
    background: $gradient;
    padding-bottom: 6rem;

    &__title,
    &__info {
        padding: 0 4rem;

        .iframe-video {
            top: 54%;
        }

        img{
            margin-top: 2rem;
        }
    }

    h2 {
        margin-top: -7.5rem;
        font-size: 6rem;
        z-index: 10;
        position: relative;
        top: -12px;
        left: -1px;
        text-shadow: rgba(0, 0, 0, 0.5) 0px 10px 25px, rgba(0, 0, 0, 0.5) 0px 1px 2px;
    }

    p {
        margin: 4em 0;
        max-width: 65rem;
    }

    h2, p {
        color: #fff;
    }

    &__video iframe,
    &__image img {
        width: 100%;
        max-width: 100%;
        margin: 0;
    }

    .f-video--youtube {
        width: 100%;
        max-width: 100% !important;
        margin: 0;
    }

    &-visual {
        max-height: 38vw;
        line-height: 0;
        overflow: hidden;
    }

    &.noVisual {
        padding-top: 10rem;

        h2 {
            margin-top: 0rem;
            text-shadow: none;
        }

        p {
            margin: 2em 0 4em;
        }
    }
}

.highlights {
    margin-top: -6rem;

    .hl-list {
        list-style: none;
        margin: -1.5rem;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
    }

    li {
        min-width: 50%;
        flex-grow: 1;
        margin: 0;
        padding: 1.5rem;
    }

    .hl {

        &:after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0) 100%);
            position: absolute;
            left: 0;
            top: 0;
            opacity: .2;
        }

        &:hover {
            .hl-img {
                transform: scale(1.075);
            }
        }

        &-img {
            transform-origin: center;
            transition: transform .95s ease;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            background-size: cover;
            background-position: center;
        }

        height: 34rem;
        min-width: 350px;
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.4);

        h2 {
            position: absolute;
            bottom: 10px;
            left: 4rem;
            text-shadow: rgba(0, 0, 0, 0.5) 0px 10px 25px, rgba(0, 0, 0, 0.5) 0px 1px 2px;
            color: #fff;
            z-index: 10;
        }

        img {
            display: none;
        }
    }
}


.quote-img-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: inline-block;
    overflow: hidden;
}

.quote-img {
    height: 100%;
    margin: 0 0 0 auto;
    width: 75%;
    background-size: cover;

    img {
        display: none;
    }
}

.quote-container {
    position: relative;
    background: #373737;
    margin-top: 10rem;

    .container {
        position: relative;
        top: -5rem;
    }

    &:after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 50%;
        position: absolute;
        left: 25%;
        top: 0;
        background-image: linear-gradient(to right, #373737, transparent);
    }

    /*img {
        position: absolute;
        top: -20%;
        right: 0;
        width: 75%;
    }*/
}

.quote {
    width: 50%;
    z-index: 1;
    position: relative;
    border-radius: 16px;
    border: 1px solid #EA811E;
    padding: 3rem;

    p, h1, span {
        color: #fff;
    }

    &__text {
        padding: 6rem 3rem 2rem;
        line-height: 2;
        position: relative;

        &:before,
        &:after {
            content: "";
            width: 50px;
            height: 50px;
            background-image: url(/Assets/dist/svg/quote.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            display: inline-block;
            position: absolute;
        }

        &:before {
            top: 0;
            left: 0;
        }

        &:after {
            bottom: 0;
            right: 0;
        }
    }

    &__name {
        padding-left: 40%;

        h2, h3 {
            color: #fff;
            margin: 0;
        }

        h3 {
            font-size: 16px;
        }
    }
}


.kpi-header {
    padding-top: 20px;

    h2 {
        margin: 0;
        color: #fff;
    }
}


.kpi-overview {
    background: $gradient;
    padding: 2rem 0;
}

.kpi-header:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #fff;
    opacity: .25;
    margin: 4rem 0 0;
}

.kpi-list {
    margin: 0;
    padding: 2rem 0;
    /*display: flex;
    flex-wrap: wrap;*/
    list-style: none;

    li {
        margin: 0;
        /*width: 33.3333%;*/
        padding: 3rem;
    }
}

.kpi {
    height: 10rem;
    display: flex;

    &__icon {
        width: 40%;
        padding-right: 2rem;

        img {
            height: 100%;
            max-height: 100px;
        }
    }

    &__value {
        color: #fff;
        padding-top: .75rem;

        p:last-child {
            font-size: 4rem;
            font-weight: 700;
            line-height: 1.2;
        }
    }
}
