@import "../helpers/_variables";
 

// =================================
//   only screen and (xxx-width: 1200px)
// =================================

$fs-heading-1: round(($fs-heading-1 / 1.1) * 100) / 100;
$fs-heading-2: round(($fs-heading-2 / 1.1) * 100) / 100;

h1,
.h1 {
    font-size: $fs-heading-1;
}

h2,
.h2 {
    font-size: $fs-heading-2;
}

.page-options {
    width: 100%;
}

.container--article {
    max-width: 96%;

    .has-teaser--true & {
        margin-top: -6rem;
    }
}
