@import "../helpers/_variables";
 

// =================================
//   only screen and (xxx-width: 960px)
// ================================= 



.btn-devices {
    display: inline-block;
}

.nav_mobile_wrapper {
    width: 100%;
    min-height: 70%;
    padding: 12vh 0 0;
    position: fixed;
    top: 0;
    //bottom: 0;
    left: 0;
    background: #fff;
    flex-direction: column;
    justify-content: flex-start;
    transform: translateX(100%);

    .menu--open & {
        transform: translateX(0%);
    }
}


.nav_logo {
    flex: 0 0 auto;
}

.nav_logo-corp {
    max-width: 10rem;
    max-height: 10rem;
}

.nav_menu {
    margin: 5vh 0;
    flex: 0 0 auto;
    order: 2;
}

.nav_meta {
    width: 75%;
    flex: 0 0 auto;
    margin: 0;
    order: 1;
}

.nav-list {
    flex-direction: column;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    color: $cl-body;

    &_item {
        margin: 0;
    }
}

.nav-list_url {
    font-size: 2.2rem;
    padding: 1rem 0;

    &:hover,
    &:focus {
        color: $cl-primary-1;
        text-decoration: underline;
    }
}

.nav .btn-menu {
    display: flex;

    .menu--open & {
        color: $cl-body;
    }
}

.nav-content {
    padding-top: 5rem;
}


.page-aside {

    &.page-aside--nav {
        max-width: 48rem;
        width: 90%;
        height: 100%;
        padding: 0;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 56;
        overflow: auto;
        transform: translateX(-100%);
        opacity: 0;
        pointer-events: none;
        background-color: #FCFCFC;
        transition: all ease 0.3s;

        .btn-close {
            right: 0;
            z-index: 2;
        }

        .artnav--open & {
            opacity: 1;
            transform: translateX(0%);
            pointer-events: all;
        }
    }
}

.nav-article {
    height: 100%;
}

.btn-article {
    position: relative;
    padding-left: 0.3em; // Align with article title
}


.main-content-wrapper {
    flex-wrap: wrap;
    justify-content: flex-end; // Aligns page-options to right
}

.main-content {
    margin-top: 5rem; // Space for sticky nav

    &.main--home,
    &.has-teaser--true {
        margin-top: 5rem;
    }
}

.summary-visual {
    max-height: 42vw;
}

.container--nav {
    padding: 0 2rem;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.2);
}

.container--article {
    max-width: calc(100% - 30px);
    padding: 1rem 2rem;
    display: block;
    position: relative;
    border-radius: 0;

    .has-teaser--true & {
        margin-top: -4.5rem;
    }
}



.article-content-container {
    word-wrap: break-word;
    word-break: break-word;

    .main--search & {
        padding-top: 1rem;
    }
}

.article-content {
    width: 100%;
    padding-top: 0;
    border-left: 0;
}

.f-tabs-output_tabs {

    .f-tabs-output_tab {
        font-size: 1.4rem;
    }
}

.f-tabs-output_content {
    padding: 3rem 2rem;
}

.content-home-wrapper {
    //padding: 3rem 2rem;
}

.page-options {
    flex: 0 0 24rem;
}

.article-related {
    flex: 1 1 20rem;
}

.article-related_teaser {
    margin: 0 0 .6rem 0;
}

.f-carousel {

    h1 {
        font-size: 2.8rem;

        .ve-homeview & {
            font-size: 2.2rem;
        }
    }

    p {
        font-size: initial;

        .ve-homeview & { 
            font-size: initial;
        }
    }
}


.f-carousel_cell-content {

    .ve-homeview & {
        max-width: 60rem;
    }
}


.f-block-column {
    margin-bottom: 2rem;
}

.sub-nav ul {
    display: none;
}

.nav_mobile_container {
    position: absolute;
    top: 15px;
    right: 10px;
}

.nav_logo {
    margin: .75rem 0;
    transform: scale(.75);
}

.container--article,
.container--downloads,
.container--myreport {
    margin-top: 1.5rem;
}


.quote {
    width: 80%;
}

.quote-container:after {
    width: 75%;
}

.highlights .hl h2 {
    left: 3rem;
    font-size: 3rem;
}

.summary.noVisual {
    padding-top: 6rem;
}