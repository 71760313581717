@import "../helpers/_variables";
 
.table {
    margin: 1.5em 0 2.5em 0;
    overflow: auto;
    border-radius: 3px;
}

// Tables
table {
    width: 100%;
    border: 0;
    border-collapse: collapse;
    border-spacing: 0;
    font-family: $set-ff-tables;
    font-size: $fs-tables;
    font-feature-settings: $ffs-tables;
    line-height: $lh-tables;
}

td {

    @include rmMarginFirstLast();
}

td,
th {
    padding: .4em .5em;
    border-bottom: 1px solid #e8e8e8;

    p {
        margin: 0.2em 0;
    }
}

div.table .shrink-1 {
    font-size:$fs-tables-shrink-1;
    line-height: $lh-tables-shrink-1;
    
    tr {
        line-height:1.2em;
    }
}

div.table .shrink-2 {
    font-size:$fs-tables-shrink-2;
    line-height: $lh-tables-shrink-2;
    
    tr {
        line-height:1.2em;
    }
}