@import "../helpers/_variables";

.prevnext {
    margin: 1rem auto 0 auto;
    padding: 1rem 0 0 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    //border-top: 0.1rem solid $cl-primary-2;
    color: $cl-body;

    .main--preview &,
    .main--page & {
        display: none;
    }

    a {
        text-decoration: none;
    }
}

.prevnext_url {
    padding: 1rem 0;
    flex: 1 1 10rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    transition: background ease 0.4s;
    color: $cl-body;

    .s_no-flexbox & {
        width: 50%;
        float: left;
    }
}

.prevnext-icon {
    width: 0.8rem;
    height: 1.5rem;
    flex: 0 0 auto;
    transition: transform ease 0.2s;
    top: 3px;
    position: relative;
}


.prevnext_descr {
    padding: 0 1rem;
    font-weight: normal;
    flex: 1 1 auto;

    .prevnext_prev & {
        text-align: left;
    }

    .prevnext_next & {
        text-align: right;
    }
}

.prevnext_prev {

    &:hover,
    &:focus {
        //color: $cl-primary-2;

        .prevnext-icon {
            transform: translateX(-0.3rem);
        }
    }
}

.prevnext_next {

    &:hover,
    &:focus {

        .prevnext-icon {
            transform: translateX(0.3rem);
        }
    }
}

.prevnext_title {
    display: block;
    line-height: 1; // Align with icon, possible because title is only one word
    font-weight: bold;
    font-family: $set-ff-base-2;
    color: $cl-primary-1;
}
.prevnext_article-title {
    font-size: 1.2rem;
}

