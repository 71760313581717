@import "../helpers/_variables";
 
.highcharts-container {
    max-width: 100%;
    font-feature-settings: $ffs-highcharts;
}

.highchart {
    margin: 0 0 2em 0;
    font-feature-settings: $ffs-highcharts;
}

.chart-group {
    font-feature-settings: $ffs-highcharts;
    @include clear();

    &_title {
        margin: 1em 0 0.3em 0;

        &.selected {
            color: $cl-primary-1;

            a {
                color: inherit;
            }
        }
    }

    &_block {
        font-weight: 400;
        font-size: 1.4rem;
    }

    &_option {
        width: 100%;
        margin: 0.3em 0 0.5em 0;
        padding: 0 0 0 1.4em;
        display: inline-block;
        position: relative;
        word-wrap: break-word;
        overflow-wrap: break-word;

        .chart-period & {
            margin: 0 0 0.2em 0;
        }

        &-radio,
        &-checkbox {
            position: absolute;
            left: 0;
            top: 0.34em;
            cursor: pointer;
        }

        &-checkbox {

        }

        &-radio {

        }

        &-label {
            cursor: pointer;
        }
    }
}

.chart-toolbox {

    &_list {
        margin: 0;
        padding: 0;
    }
}

.chart-period {
    width: 60%;
    float: left;
    padding: 0 2em 0 0;

    &_list {
        margin: 0;
        padding: 0;
        list-style: none;

        &-item {
            margin: 0.1em 0;
        }
    }
}

.chart-info {
    width: 100%;
    margin: 2em 0 1.6em 0;
    display: inline-block;
}

.chart-buttonbox {
    
    &--preview-type {
        max-width: 40%;
        float: right;
    }

    &_list {
        margin: 0;
        padding: 0;
        list-style: none;

        &-item {
            margin: 0 1em 0 0;
            display: inline-block;
        }
    }

    &-url {
        display: inline-block;
    }

    img {
        width: 32px;
        height: 32px;
    }
}

.chart-message-js {
    padding: 0 2rem;
    font-size: 1.2rem;
}


// Charts page

.chart-buttonbox,
.chart-toolbox {
    ul {
        padding: 0;
        list-style: none;
    }
}

.chart-categorybox {
    padding: 1rem 2rem 1rem 4rem;

    h5 {
        margin: 0 0 1rem 0;
        text-transform: none;
        font-family: $set-ff-base-1;
    }
}

.cat-container {
    margin: 0;

    .block {
        margin: 0.2rem 0 1rem 0;
        padding: 0 0 0 1rem;
        font-size: 1.4rem;
    }
}