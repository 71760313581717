@import "../helpers/_variables";

// =================================
//   Facetmenu
// =================================

.nav-content_fc {
    
}

.facets-group {
    margin: 1rem 0 0 0;
    padding: 1rem 0;
    border-bottom: 0.1rem solid #d1d1d1;
    font-weight: 500;

    .facet-input {
        width: 1.4rem;
        height: 1.4rem;
        margin: 0.3rem 0.6rem 0 0;
        flex: 0 0 auto;
    }

    .facet-label {
        cursor: pointer;
        flex: 1 1 auto;
        font-weight: 400;
    }
}

.facet-group {
    width: 100%;
    margin: 0.4rem 0;
    font-size: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &.state--disabled {
        opacity: 0.7;
    }

    &:first-of-type {
        margin-top: 0;
    }
}

.facets-title {
    width: 100%;
    margin: 1rem 0 0 -0.1em; // -0.1em for better alignment with custom font
    padding: 0;
    font-size: 2rem;
}