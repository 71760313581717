
// =================================
//   Declare font families
// =================================

// System fonts
$ff-family-sans-serif:  Arial, sans-serif;
$ff-family-serif:       Georgia, "Times New Roman", Times, serif;
$ff-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;

// (FF)Font-family
$ff-muli:       'Muli', $ff-family-sans-serif; // Sans-serif
$ff-zillaslab:  'Zilla Slab', $ff-family-serif; // Serif
$ff-lora:       'Lora', $ff-family-serif; // Serif
$ff-averta: 'Averta';

$ff-verdana: 'Verdana';

// Font display method:
$font-display: swap;

// (FFS)Font-Feature-Settings
// Conditions: Font supports features & Site visitors use 'newer' browsers

// Enable Ligatures (https://en.wikipedia.org/wiki/Typographic_ligature)
$ffs-headings: "liga" 1, "zero" 1; 

// Enables Tabular Figures in tables & charts (https://www.fonts.com/content/learning/fontology/level-3/numbers/proportional-vs-tabular-figures)
// Not in bodytext because it creates uneven spacing.
$ffs-tables: "tnum" 1;
$ffs-highcharts: "tnum" 1;


// =================================
// (set-ff)Set Font Family
// =================================

// Font Family 'Body'
$set-ff-body: $ff-averta;

// Font Family 'Article'
$set-ff-article: $ff-averta;

// Font Family 'Headings'
$set-ff-headings: $ff-averta;

// Font Family 'Introduction Text'
$set-ff-introduction: $ff-averta;

// Font Family 'Blockquote'
$set-ff-blockquote: $ff-averta;

// Font Family 'Tables'
$set-ff-tables: $ff-averta;

// Font Family 'Random'
// Used throughout the project on random elements
$set-ff-base-1: $ff-averta;
$set-ff-base-2: $ff-averta;

// =================================
//   Set (FW)Font Weights
// =================================

// Font Weight 'Body'
$fw-body: normal;

// Font Weight 'Article'
$fw-article: inherit;

// Font Weight 'Headings'
$fw-headings: 700;

// Font Weights individual headings
$fw-heading-1: $fw-headings;
$fw-heading-2: $fw-headings;
$fw-heading-3: 600;
$fw-heading-4: 600;
$fw-heading-5: 600;
$fw-heading-6: 600;

// Font Weight 'Introduction Text'
$fw-introduction: bold;

// Font Weight 'Blockquote'
$fw-blockquote: bold;
$fw-blockquote-caption: normal;

// Font Weight 'Navigation'
$fw-navigation: normal;


// =================================
//   Set (FS)Font Size
// =================================
// Try to prevent smaller than 1.4rem, 
// so a wide range of people can read it.
// When an exotic font-family is used, adjust accordingly

// Font Size 'Body'
// Do not change 'Body' size without knowing the consquences
// Needs to be 1.6rem because of rem usage.
$fs-body: 1.5rem;

// Font Size 'Article'
$fs-article: $fs-body;

// Font Size 'Breadcrumbs'
$fs-breadcrumbs: 1.2rem;

// Font Sizes individual headings
$fs-heading-1: 5rem;
$fs-heading-2: 4rem;
$fs-heading-3: 3rem;
$fs-heading-4: 2rem;
$fs-heading-5: 1.6rem;
$fs-heading-6: 1.4rem;

// Font Size 'Introduction Text'
$fs-introduction: 125%; // 125% = 20px at 16px base

// Font Size 'Blockquote'
$fs-blockquote: initial;
$fs-blockquote-caption: 75%; // 75% = 12px at 16px base

// Font Size 'Tables'
$fs-tables: 1.4rem;
$fs-tables-shrink-1: 1.3rem;
$fs-tables-shrink-2: 1.1rem;

// Font Size 'Navigation'
$fs-navigation: $fs-body;

// Font Size 'Footnotes'
$fs-footnotes: 75%;


// =================================
//   Set (LH)Line-height
// =================================

$normal: 1.9;

// Line-height 'Body'
$lh-body: $normal;

// Line-height 'Article'
$lh-article: $normal;

// Line-height 'Headings'
$lh-headings: 1.1;

// Line-heights individual headings
$lh-heading-1: $lh-headings;
$lh-heading-2: $lh-headings;
$lh-heading-3: $lh-headings;
$lh-heading-4: $lh-headings;
$lh-heading-5: $lh-headings;
$lh-heading-6: $lh-headings;

// Line-height 'Introduction Text'
$lh-introduction: $normal;

// Line-height 'Blockquote'
$lh-blockquote: $normal;

// Line-height 'Buttons'
$lh-buttons: inherit;

// Line-height 'Tables'
$lh-tables: normal;
$lh-tables-shrink-1: normal;
$lh-tables-shrink-2: normal;



// =================================
//   Set (WS)White-Space
//   Using shorthand property
// =================================

// White-space 'Paragraph'
$ws-paragraph: 0.1em 0 1.75em 0;

// White-space 'Headings'
$ws-headings: 0.75em 0 0.5em 0;

// White-spaces individual headings
$ws-heading-1: $ws-headings;
$ws-heading-2: $ws-headings;
$ws-heading-3: $ws-headings;
$ws-heading-4: $ws-headings;
$ws-heading-5: $ws-headings;
$ws-heading-6: $ws-headings;

// White-space 'Introduction Text'
$ws-introduction: 0.7em 0 0.8em 0;

// White-space 'Blockquote'
$ws-blockquote: 2em 0 2em 0;

// White-space 'Lists'
$ws-lists: 0.1em 0 1.2em 0;

