@import "../helpers/_variables";


.search-form {
    max-width: 80rem;
    width: 100%;
    margin: 0 0 1rem 0;
    //border-bottom: 1px solid $cl-primary-1;
    overflow: hidden;
    background: #FFF;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    border-radius: 3px;
    padding: 8px 10px;
    // When in header
    //position: absolute;
    //right: 0;
    //bottom: 0;
    //transform: translateY(120%);
    //transform: translateY(calc(100% + 1.6vh));
    .svg-icon-reset {
        width: 2rem;
        height: 2rem;
        opacity: 0.7;
    }

    .btn-reset {
        padding-right: 0;
    }
}

.btn-reset {

    &:focus,
    &:hover {

        .svg-icon-reset {
            opacity: 0.9;
        }
    }
}

.search-input,
.search-submit {
    display: inline-block;
    vertical-align: top;
}

.search-input {
    width: 85%;
    padding: .6em 2em .4em .4em;
    float: right;
    flex: 1 1 auto;
    border: 0;
    outline: 0;
    font-weight: 400;
    flex: 1 1 100%;
    @include ellipsis();

    &::-ms-clear {
        width: 0;
        height: 0;
        display: none;
        overflow: hidden;
    }

    &:focus,
    &:active {

    }
}

.search-submit {
    width: 15%;
    float: left;
    display: inline-block;
    padding: .4em .2em .2em .4em;
    border: 0;
    outline: 0;
    color: $cl-primary-1;
    text-decoration: none;
    flex: 0 0 4rem;
    text-align: left;

    &:focus,
    &:hover {
        color: $cl-primary-2;
    }

    .svg-icon-search {
        width: 2.4rem;
        height: 2.5rem;
    }
}