@import "../helpers/_variables";

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }

    10% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes fade-in-small {
    0% {
        opacity: 0;
        transform: translateY(30%);
    }

    10% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}