@import "../helpers/_variables.scss";

.myreport-item {
    width: 100%;
    margin: 0 0 1rem 0;
    padding: .3em .4em;
    clear: both;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    list-style: none;
    overflow: hidden;

    a {
        display: inline-block;
        text-decoration: none;
    }

    .svg-icon {
        @include no-selection();
    }

    .btn {
        margin: 0;
        flex: 0 0 2.4rem;

        &:first-of-type {
            margin-right: 0.4rem;
        }

        &:last-of-type {
            margin-left: 0.4rem;
        }
    }

    &:focus,
    &:hover {
        background-color: #eeeeee;
    }
}

.mr-list_form {
    float: left;
    flex: 1 1 auto;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

/*.mr-list_options {
    padding: 0 2rem 0 0;
    float: left;
    flex: 0 0 8rem;
    font-size: 1.2rem;


    .btn {
        margin: 0 0.2em;

        &:last-of-type {
            margin-right: 0;
        }
    }
}*/

.mr-list_label,
.mr-list_input {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
}

.mr-list_label {
    color: $cl-hlink;
    
    @include break-word();
    @include no-selection();
}

.mr-list_input {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0.3em 1rem 0 0;
    flex: 0 0 2rem;
}

.myreport-download {
    width: 100%;
    margin: 3rem 0 0;
    padding: 0;
    clear: both;
}
