@import "../helpers/_variables";

// =================================
// CSS Icons
// =================================

.css-icon-menu {
    width: 2.6rem;
    height: 1.9rem;
    position: relative;

    span:nth-child(1),
    span:nth-child(2),
    span:nth-child(3) {
        min-height: 3px;
        width: 100%;
        height: 0.3rem;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        background: currentColor;
        transition: transform ease 0.26s, background ease 0.2s, opacity ease 0.2s, width ease 0.4s;
        transform: translateZ(0);
    }

    span:nth-child(1) {
        top: 0;
        transform-origin: top left;
    }

    span:nth-child(2) {
        top: 0;
        bottom: 0;
        opacity: 1;
        transform-origin: center left;
    }

    span:nth-child(3) {
        bottom: 0;
        transform-origin: bottom left;
    }

    .menu--open & {

        span:nth-child(1) {
            transform: rotate(40deg) translateZ(0);
        }

        span:nth-child(2) {
            //width: 0%;
            opacity: 0;
            transform: translateX(-30%) translateZ(0);
        }

        span:nth-child(3) {
            transform: rotate(-40deg) translateZ(0);
        }
    }
}

.css-icon-article {
    width: 2.6rem;
    height: 1.9rem;
    position: relative;

    span:nth-child(1),
    span:nth-child(2),
    span:nth-child(3) {
        min-height: 3px;
        width: 2rem;
        height: 0.3rem;
        margin: auto;
        position: absolute;
        right: 0;
        background: currentColor;
        transition: transform ease 0.26s, background ease 0.2s, opacity ease 0.2s, color ease 0.2s;

        &::before {
            width: 0.4rem;
            height: 0.3rem;
            content: "";
            position: absolute;
            top: 0;
            left: -0.6rem;
            background: currentColor;
            transition: opacity ease 0.4s;
        }
    }

    span:nth-child(1) {
        top: 0;
        transform-origin: top left;
    }

    span:nth-child(2) {
        top: 0;
        bottom: 0;
        transform-origin: center left;
    }

    span:nth-child(3) {
        bottom: 0;
        transform-origin: bottom left;
    }

    &:hover,
    &:focus,
    .btn:hover &,
    .btn:focus & {
        color: currentColor;

        span:nth-child(1) {
        }

        span:nth-child(2) {
            transform: translateX(0.4rem);
        }

        span:nth-child(3) {
        }
    }
}

.css-icon-facet {
    width: 2.6rem;
    height: 1.9rem;
    position: relative;

    span:nth-child(1),
    span:nth-child(2),
    span:nth-child(3) {
        min-height: 3px;
        width: 100%;
        height: 0.3rem;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        background: currentColor;
        transition: all ease 0.2s;
    }

    span:nth-child(1) {
        top: 0;
        transform-origin: top left;
    }

    span:nth-child(2) {
        width: 1.7rem;
        top: 0;
        bottom: 0;
        transform-origin: center left;
    }

    span:nth-child(3) {
        width: 1.1rem;
        bottom: 0;
        transform-origin: bottom left;
    }

    &:hover,
    &:focus,
    .btn:hover &,
    .btn:focus & {
        color: currentColor;
    }
}

.css-icon {
    display: inline-block;
    vertical-align: middle;
    color: currentColor;
}

.css-icon-close {
    width: 1.5em;
    height: 1.5em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    clip-path: circle(0.6em);
    transition: clip-path ease 0.2s;

    &::before,
    &::after {
        content: "";
        width: 100%;
        height: 0.16em;
        margin: auto;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: currentColor;
        transform-origin: center;
        border-radius: 0.1em;
    }

    &::before {
        transform: rotateZ(-45deg) translateX(0em);
    }

    &::after {
        transform: rotateZ(45deg) translateX(0em);
    }

    &:hover,
    &:focus,
    .btn:hover & {
        clip-path: circle(1.5em);
    }
}

.css-icon-social {
    width: 4rem;
    height: 4rem;
    display: inline-block; //Fallback
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    font-family: $set-ff-body;
    position: relative;
    border: 0.1rem solid currentColor;
    background-color: transparent;
    transition: all ease 0.2s;

    .f_no-flexbox & {

        &::before {
            margin: auto;
            position: absolute;
            top: 23%;
            left: 0;
            right: 0;
            line-height: 1;
        }
    }

    &::before {
        display: inline-block;
        flex: 0 0 auto;
    }
}

.css-icon_fb {

    &::before {
        content:"f";
    }
}

.css-icon_twitter {

    &::before {
        content:"t";
    }
}

.css-icon_linkedin {

    &::before {
        content:"in";
    }
}

.css-icon_youtube {
    font-size: 80%;
    text-transform: uppercase;

    &::before {
        content:"yt";
    }
}

.css-icon_pinterest {
    font-size: 80%;
    text-transform: uppercase;

    &::before {
        content:"p";
    }
}

