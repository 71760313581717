
@mixin fa-icon() {
  display: inline-block;
  //font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} FontAwesome; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin clear() {
  
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin ellipsis() {
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin no-text() {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

@mixin appearance($val: none) {
    -webkit-appearance: $val;
    -moz-appearance: $val;
    appearance: $val;
}

@mixin custom-selection($color-txt, $color-bg) {
    &::-moz-selection {
        color: $color-txt;
        background: $color-bg;
    }

    &::selection {
        color: $color-txt;
        background: $color-bg;
    }
}

@mixin no-selection() {
    &::-moz-selection {
        color: initial;
        background: none;
    }

    &::selection {
        color: initial;
        background: none;
    }
}

@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Creating scalable elements
// Example: @include responsive-ratio(16,9)
@mixin responsive-ratio($x, $y, $pseudo: false) {
    $padding: unquote( ( $y / $x) * 100 + '%');

    @if $pseudo {
        &::before {
            content: "";
            display: block;
            width: 100%;
            padding-bottom: $padding;
        }
    }
    @else {
        display: block;
        width: 100%;
        padding-bottom: $padding;
    }
}

@mixin rmMarginFirstLast() {

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

@mixin hyphenate($type: auto) {
  word-wrap: break-word;
  overflow-wrap: break-word;

-webkit-hyphens: $type;
   -moz-hyphens: $type;
        hyphens: $type;
}

@mixin break-word() {
    word-break: break-word; // Older syntax for fallback
    overflow-wrap: break-word;
}

@mixin transition-delay($i, $startDelay, $AddedDelay) {

    @for $i from 1 through $i {
        $AddedDelay: $i * $AddedDelay;
        $delay: $startDelay + $AddedDelay;

        &:nth-of-type(#{$i}) {
            transition-delay: #{$delay};
        }
    }
}

@mixin animation-delay($i, $startDelay, $AddedDelay) {

    @for $i from 1 through $i {
        $AddedDelay: $i * $AddedDelay;
        $delay: $startDelay + $AddedDelay;

        &:nth-of-type(#{$i}) {
            animation-delay: #{$delay};
        }
    }
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
    
    @return $number;
}

// Convert rem base to em base, 
@function rem-to-em($bp-rem, $base-size: 16) {
    $bp-value: strip-unit($bp-rem);
    $rem-to-px: ($bp-value * 10); // Shift 1 decimal
    $convert: round(($rem-to-px / $base-size) * 1000) / 1000; // Round to 2 decimals

    @return #{$convert}em;
}


@mixin fb-make-row($grid-gutter-width: 1.5rem) {
    display: flex;
    flex-wrap: wrap;
    margin-right: ($grid-gutter-width / -2);
    margin-left: ($grid-gutter-width / -2);
}

@mixin fb-make-col-ready($grid-gutter-width: 1.5rem) {
    position: relative;
    min-height: 1px; // Prevent collapsing
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
    flex: 1 1 auto;
}

@mixin fb-make-col($size: 100%, $columns: 12) {
    //max-width: (percentage($size / $columns) * 2);
    //width: percentage($size / $columns);
    flex: 1 1 percentage($size / $columns);
}

@mixin fb-make-cols($columns: 12, $max-col-width: 100%) {

    @for $i from 1 through $columns {
        &-#{$i} {
            @include fb-make-col($i, $columns);
        }
    }
}