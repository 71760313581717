@import "../helpers/_variables";

// =================================
//   Report basket / Page options
// =================================

.myreport {

    .svg-icon {
        vertical-align: middle;
    }
}

.btn-option-remove {
    margin: 0 1rem 0 0;
}

.myreport-option {
    margin: 0 0 0.2em 0;
    padding: 0.2em 0;
    display: inline-block;
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: bold;
    text-decoration: none;
    color: $cl-primary-1;
    position: relative;

    span {
        vertical-align: middle;
    }

    .svg-icon-close {
        width: 1.6rem;
        height: 1.6rem;
    }
}

.myreport-count {
    position: absolute;
    right: -12px;
    top: -7px;
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
    font-weight: 700;
    font-family: $ff-family-sans-serif;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    //background-color: $cl-primary-2;
    border-radius: 1.2rem;
    box-sizing: content-box;
    z-index: 2;

    &:before {
        content: "";
        display: inline-block;
        width: 18px;
        height: 18px;
        background: $vf-red;
        border-radius: 20px;
        position: absolute;
        left: 0;
        left: 50%;
        top: -1px;
        z-index: -1;
        transform: translateX(-50%);
    }
}
