@import "../helpers/_variables";

// =================================
//   Accessibility 
// =================================

.sreaders,
.access-readers {
    width: 1px;
    height: 1px;
    position: absolute;
    top: -100em;
    left: -100em;
    overflow: hidden;
    opacity: 0.01;
}

a.skip-to-content {
    padding: 1.75% 2% 1.5% 2%;
    position: absolute;
    top: -16em;
    z-index: 700;
    color: #000;
    background: #FFF;
    text-decoration: none;
    border-bottom: 2px solid lighten(#000, 40%);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;

    &:focus {
        top: -0.5em;
        left: 0;
        outline: 0;
    }

    &:hover {
        border-bottom: 4px solid #000;
    }

    .refPreview &,
    .ve-previewview & {
        display: none;
    }
}

.no-outline {
    outline: 0;
}