@import "../helpers/_variables";

.paging {
    margin: 2rem 0 1rem 0;
    padding: 0 2rem;
    display: block;
    text-align: left;
    display: flex;
    font-size: 14px;

    .paging-page {
        background: $vz-orange;
        padding: .4rem 1.1rem;
        color: #fff;
        border-radius: 5px;
        margin-right: .4rem;

        &:hover,
        &:focus {
            background: $vf-red;
        }
    }

    .page--active {
        cursor: default;
        background: $vf-red;

        &:hover,
        &:focus {
            cursor: default;
        }
    }
}

