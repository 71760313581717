@import "../helpers/_variables";

.f-tabs-output {
    max-width: 100rem;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 2rem -0.4rem rgba(0, 0, 0, 0.2);
}

.f-tabs-output_tabs {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    background-color: $cl-bg-body;

    @media screen and (max-width: 620px) {
        display: none;
    }

    .f-tabs-output_tab {
        margin: 0;
        padding: 3rem 0.6rem;
        color: $cl-body;
        font-size: 2rem;
        font-weight: bold;
        font-family: $set-ff-base-2;
        flex: 1 1 auto;
        position: relative;
        z-index: 4;
        transition: background ease 0.2s;
        overflow: hidden;

        &:focus,
        &:hover {
            margin-right: 0;
            z-index: 3;
            background-color: darken($cl-bg-body, 5%);
        }

        &::after,
        &::before {
            content:"";
            width: 0%;
            height: 0.3rem;
            display: block;
            position: absolute;
            left: 50%;
            bottom: 0;
            transition: width ease 0.4s 0.45s, background-color ease 0.1s 0.8s;
            transform: translateX(-50%);
        }

        &::before {
            z-index: 2;
            background-color: darken($cl-bg-body, 5%);
        }

        &::after {
            width: 100%;
            z-index: 1;
            background-color: darken($cl-bg-body, 5%);
        }


        &.state--active {
            cursor: default;
            z-index: 2;
            background-color: $cl-bg-body;

            &::before {
                width: 100%;
                background-color: $cl-primary-2;
                transition: width ease 0.4s 0.05s, background-color ease 0.1s 0s;
            }

            &:focus,
            &:hover {
                z-index: 2;
                background-color: $cl-bg-body;
            }
        }
    }
}

.f-tabs-output_content-wrapper {

}

.f-tabs-output_content {
    padding: 5rem 4rem 3rem 4rem;
    display: none;
    text-align: center;
    overflow: hidden; // Hide image when offset outside of view
    // Newer browsers
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-bottom: 0.1rem solid darken($cl-bg-body, 5%);

    @media screen and (max-width: 620px) {
        display: block;
    }

    &.state--active {
        display: block;
        text-align: center;

        @media screen and (max-width: 620px) {
            display: block;
        }
    }
}

.f-tabs-output_content-title {
    display: none;
    margin: 0 0 1em 0;

    // Custom media queries, content requires it.
    @media screen and (max-width: 620px) {
        display: block;
    }
}

.f-tabs-output_content-chart {
    margin: 0 auto;
}

.chart-container {
    animation: fade-in 0.6s ease 1 normal;
    animation-fill-mode: both;
    animation-play-state: paused;

    @media screen and (max-width: 620px) {
        animation: none;
        flex: 1 1 auto;
    }

    .state--active & {
        animation-play-state: running;
    }
}

.f-tabs-output_image {
    max-width: 30rem;
    min-width: 10rem;
    flex: 1 1 auto;
    display: inline-block; // Simple Fallback in case of no flexbox support
    vertical-align: top;

    animation: fade-in 0.6s ease 1 normal;
    animation-fill-mode: both;
    animation-play-state: paused;

    @media screen and (max-width: 620px) {
        animation: none;
        flex: 1 1 auto;
    }

    .state--active & {
        animation-play-state: running;
    }

    &:nth-of-type(2) {
        animation-delay: 0.2s;
    }

    &:nth-of-type(3) {
        animation-delay: 0.4s;
    }

    &:nth-of-type(4) {
        animation-delay: 0.6s;
    }

    &:nth-of-type(5) {
        animation-delay: 0.8s;
    }

}