@import "../helpers/_variables";

.f-carousel {
    margin-top: 4rem;
    margin-bottom: 4rem;
    color: #FFF;
    position: relative;

    &.f-carousel--single {
        overflow: hidden;
    }

    /*.ve-homeview & {
        margin: 0;
    }*/

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: inherit;
    }

    h1 {
        margin: 1rem 0 3rem 0;
        font-size: 3rem;
        display: block;
        text-shadow: 0.15rem 0.15rem 0 rgba(0, 0, 0, 0.2);
    }

    p,
    a {
        font-weight: 500;
        //text-shadow: 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.2);
    }

    .btn {
        font-size: 1.8rem;
        text-shadow: none;
    }

    .flickity-viewport {
        overflow: hidden;
    }
}

.f-carousel_cell {
    max-height: 74rem;
    min-height: 26rem;
    width: 100%;
    padding: 1rem 0;
    background-size: cover;
    background-position: 50% 50%;
    text-align: center;

    .f-carousel_ratio {
        @include responsive-ratio(2200, 1250, false);
        //max-height: 74rem;
        //min-height: 40rem;
        display: flex;
        flex: 0 1 auto;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
    }

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.2);
    }

    &.f-carousel_cell--only-image {

        &::after {
            display: none;
            content: none;
        }
    }

    h1,
    h2,
    h3 {
        text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
    }
}


.f-carousel_cell-container {
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0 5.6rem; // Reserve space for prev/next
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    /*.ve-homeview & {
        padding: 0 0 8vw 10vw;
        justify-content: flex-start;
        align-items: flex-end;
    }*/
}

// Extra wrapper needed for Edge bug
// pseudo elements ratio trick didn't work
// so seperate div is needed and the way items where aligned changed.
.f-carousel_cell-content {

    .ve-homeview & {
        min-width: 30rem;
        max-width: 68rem;
        padding: 2.4rem 3rem;
        text-align: left;
        background-color: rgba($cl-primary-1, 0.4);
    }

    p {
        display: none;
        font-size: 2rem;
        font-weight: normal;
        margin: .1em 0 1em 0;

        .ve-homeview & { 
            display: block;
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    .f-btn-link {
        margin-top: 0;
    }
}
