@import "../helpers/_variables";


.article-header {
    min-height: 28rem;
    max-height: 72rem;
    width: 100%;
    margin: 0;
    padding: 0;
    flex: 0 0 auto;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &::before {
        content: "";
        display: block;
        @include responsive-ratio(1440, 280);
        visibility: hidden;
    }


    &.article-header--home {
        min-height: 20rem;
        max-height: 70rem;
        text-align: center;
        color: #FFF;
    }
}

/*.article-header_content {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 2;
    transform: translateY(-50%);
}*/

