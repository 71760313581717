@import "../helpers/_variables";

.article-related {
    padding: 0 2rem 2rem 2rem;
    font-size: 1.4rem;

    a {
        position: relative;
        color: $cl-hlink;
        text-decoration: none;
        
        &:hover,
        &:focus {
            color: darken($cl-hlink, 15%);
            text-decoration: none;
        }
    }
}

.article-related_item {
    border-bottom: 0.1rem solid #d1d1d1;
    
    &:last-of-type {
        border: 0;
    }

}

/*.article-related_title {
    margin: 0 0 1rem;
    padding: 0 0 0.4rem 0;
    font-size: 1.6rem;
    font-weight: 600;
}*/

.article-related_url {
    padding: 1rem 0;
    display: block;


    &:hover,
    &:focus {
        
        .article-related_teaser {
            clip-path:  polygon(80% 0, 100% 50%, 80% 100%, 0 100%, 0 0);
        }
    }
}

.article-related_list {
    margin: 0;
    padding: 0;
    list-style: none;
}


.article-related_teaser {
    max-width: 33rem;
    width: 100%;
    margin: 0 auto 0.6rem auto;
    display: block;
    overflow: hidden;
    background: url('') no-repeat center center transparent;
    background-size: cover;
    transition: filter ease 0.2s;
    transition: clip-path ease 0.3s;
    clip-path:  polygon(100% 0, 100% 51%, 56% 100%, 0 100%, 0 0);

    @include responsive-ratio(400, 300, true)
}