@import "../helpers/_variables.scss";

/*!
 * @company:    F19 - Digital First Reporting
 */


// =================================
//   Base CSS 
// =================================

// Using inherit makes it easier to override box-sizing later on.
*,
*:before,
*:after {
    box-sizing: inherit;
}

*:before,
*:after {
    color: currentColor;
    font-family: inherit;
    font-weight: inherit;
}

html,
body {
    width: 100%;
    height: 100%; 
    margin: 0;
    padding: 0;
    border: 0;
}

html {
    font-size: 62.5%; // Together with 1.6rem on the body we can make our lives easier (1.8rem becomes (99.9%) the same as 18px)
    box-sizing: border-box;

    // Browser(s) resets/fixes
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
} 

body {
    color: $cl-body;
    font-size: $fs-body;
    font-family: $set-ff-body;
    font-weight: $fw-body;
    line-height: $lh-body;
    background-color: $cl-bg-body;
}

svg,
img {
    max-width: 100%; // Prevents images overflowing their parent containers.
    border-style: none; // Some 'older' browsers render borders around images/svg's
    box-sizing: content-box; // Prevents image resizing with borders
}

// Reset iframe across browsers
iframe {
    margin: 0;
    padding: 0;
    display: block;
    outline: 0;
    border: 0 !important;
}

// Hide the overflow in IE.
svg:not(:root) {
  overflow: hidden;
}

svg {

    use {
        pointer-events: none;
    }
}

// Reset fieldset styles
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

// Reset
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-size: 100%;
  font-family: inherit;
  line-height: 1.15;
}

// Remove the inner border and padding in Firefox.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

// Restore the focus styles unset by the previous rule.
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

// Some 'older' browsers still need this.
figcaption,
figure,
main {
  display: block;
}


// Correct the padding in Firefox.
fieldset {
  padding: 0.35em 0.75em 0.625em;
}


// Add the correct display in IE 10-.
[hidden] {
  display: none;
}

// Add the correct display in iOS 4-7.
audio:not([controls]) {
  display: none;
  height: 0;
}

// Add the correct display in IE 9-
audio,
video {
  display: inline-block;
}