
// =================================
//   Sass Functions/Mixins
// ================================= 
@import "_functions";

// =================================
//   Color settings
// ================================= 
@import "_colors";

// =================================
//   Typography settings
// ================================= 
@import "_typography.scss";

// =================================
//   Media Query settings
// ================================= 
@import "_mq";


$voda-gutter: 3rem;