@import "../helpers/_variables";

.ve-downloadsview {

    .article-content-container {

        ul {
            margin: 2rem 0 0 0;
            padding: 0 0 0 0.4em;
            list-style: none;

            a {
                margin: 0.2em 0;
                padding: 0.2em 0;
                display: block;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: flex-start;

                &::before {
                    flex: 0 0 auto;
                    width: 1em;
                    height: 1em;
                    margin: .05em .6em 0 0;
                    content: "";
                    display: inline-block;
                    background: url('/Assets/dist/svg/tp-icon-download.svg') no-repeat center center transparent;
                    background-size: contain;
                }
            }
        }

    }
}