@import "../helpers/_variables";


.footer {
    margin: 0;
    padding: 2rem 5rem 5rem;
    position: relative;
    display: block;
    flex: 0 0 auto;
    color: $cl-footer;
    background: rgb(55, 55, 55); //$cl-bg-footer;
    //box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.3);
    //border-bottom-left-radius: 15px;
    //border-bottom-right-radius: 15px;

    flex-grow: 1;
}

.container--footer {
    /*display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;*/
    background: rgb(55, 55, 55); //$cl-bg-footer;
}

.footer {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 1em 0 0.2em 0;
        display: inline-block;
        color: #fff;
        font-size: 2rem;
        font-weight: bold;
    }

    ul, ol {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.4rem;
        line-height: 1.5;

        li {
            margin: 0.6rem 0 .2rem;
        }
    }

    a {
        color: #fff;
        text-decoration: none;
        transition: opacity 0.16s ease-in 0s;

        &:hover,
        &:focus {
            opacity: .6;
        }
    }
    // If 'render pipeline' of not assigning unique classes bug happens again
    // enable this for quick fix and talk it over with other developers.
    // This targets all classes beginning with '_', dirty fix so should be avoided.
    /*[class^="_"] {
        width: auto !important;
        display: none;
    }*/
    /*.f-area-after {

        > *:first-child {
            margin-top: 0;
        }
    }*/
}

.footer-top {
    position: relative;
    display: flex;
}

.footer-section {
    float: left;
    width: 33.3333%;
    max-width: 33.3333%;
    display: flex;
    flex-direction: column;

    a {
        margin: .2rem 0 0rem;
        font-size: 15px;
    }
}

.footer-logo {
    position: absolute;
    right: 0;
    bottom: 0;
}

.footer-bottom {

    section {
        display: flex;
        font-size: 12px;
        color: rgb(168, 168, 167);

        a {
            color: rgb(168, 168, 167);
            margin-right: 1.8rem;
        }
    }

    &:before {
        content: "";
        display: block;
        margin: 35px 0 30px;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 5px;
        background: linear-gradient(270deg, rgb(244, 140, 0) 0%, rgb(244, 140, 0) 24.65%, rgb(230, 0, 0) 74.74%, rgb(230, 0, 0) 100%);
    }
}