@import "../helpers/_variables";

.main-content {
    width: 100%;
    margin: 0 auto; // Fallback iOS 6 & 7
    //flex: 1 1 auto;

    &.has-teaser--true {
        margin-top: 0;
    }

    &.main--home {
        width: 100%;
        margin: 0 auto;
        padding: 0;
    }
}

.page-wrapper {
    /*max-width: 1040px;
    margin: 0 auto;*/
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    overflow-x: hidden; // Prevent scrollbar on elements that are positioned outside of view
    background: $dark-1;
    //padding-bottom: 6rem;
}


.container {
    max-width: $screen-nm-min;
    width: 100%;
    margin: 0 auto;
    padding: 0 $voda-gutter;

    h1 {
        max-width: 24em;
    }

    h2 {
        max-width: 30em;
    }
    // For readability, between 45-75 words on one line.
    // With EM so when used different font-size it will be in proportional.
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote {
        //max-width: 62rem;
        //max-width: 37.5em; // On font-size 16px, it will be 600px wide
    }
}

.container--home {
    max-width: 100%;
    width: 100%;

    > p {
        font-size: 1.8rem;
    }

    .subcontainer {
        max-width: $screen-nm-min;
        padding: 0 15vw;
        margin: 0 auto;
        text-align: center;

        &.f19-slider-container {
            padding: 0;
            max-width: 100%;
            margin-top: -5rem;

            .f-carousel {
                margin-top: 0;
            }
        }
    }

    > img {
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote {
        //max-width: none;
    }

    > *:nth-child(1)[src*="images"],
    > *:nth-child(1)[src*="Images"],
    > *:nth-child(1)[class*="f-video"],
    > *:nth-child(1)[class*="f-carousel"] {
        margin-top: -3rem;
    }
}

.container--article,
.container--downloads,
.container--myreport {
    z-index: 2;
    position: relative;
    max-width: $screen-nm-min;
    padding: 2rem 2rem 4rem;
    margin-top: 7.5rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: $cl-bg-main;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    border-radius: 15px;
    margin-bottom: 5rem;


    /*.ve-downloadsview & {
        max-width: $screen-md-min;
    }*/
    .has-teaser--true & {
        margin-top: -8rem;
        /*&:after {
            content: "";
            display: inline-block;
            height: 10px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(90deg, #E60000, #F48C00);
        }*/
    }
}


.container--myreport {
    padding: 5rem 2rem 8rem;
}

/*.container--downloads,
.container--chart,
.container--myreport {
    max-width: 96rem;
    width: 100%;
    padding: 2rem;
    background-color: $cl-bg-main;
    box-shadow: 0 0.4rem 3rem 0.3rem rgba(0,0,0,0.05);
}*/

.container--columns {
    max-width: 1440px;
}


/*
    If somewhere in your code there is a element with 'overflow: hidden/scroll/auto' inside of a flexbox item,
    the parent would need min-width: 0px in a horizontal flex container or min-height in a vertical flex container.
    This disables min-sizing behavior of flexbox, otherwise the flexbox item refuses to shrink smaller than the child's min-content size.

    Add this fix to every ancestor of flexed item where problem occures
*/
.main-content-wrapper,
.article-content-container {
    min-width: 1px; 
}


.main-content-wrapper {
    max-width: 72rem;
    width: 100%;
    //margin: 0 calc(2vh + 2vw) 0 auto;
    margin: 0 auto;
    flex: 1 1 72rem;
}

.article-content-container {
    width: 100%;

    .main--search &,
    .main--page & {
        max-width: 80rem;
        margin: 0 auto;
    }
}

