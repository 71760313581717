
// =================================
//   Lazy-loading styles
// =================================

.lazyload {
    clear: both;

    a {
        display: block;
    }
}