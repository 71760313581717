@import "../helpers/_variables";

.page-options_wrapper {
    //padding: 0.2rem 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    //border-top: 0.1rem solid rgba($cl-body, 0.2);
    box-shadow: rgba(0, 0, 0, 0.07) -10px 10px 25px 0px;
    border-radius: 15px;
    padding: 20px;

    .main--preview &,
    .main--page & {
        display: none;
    }
}

.page-options {
    padding: 2rem 0 1rem 0;
    flex: 0 1 26rem;

    
    &:first-of-type {

        .page-aside_title {
            margin-top: 0;
        }
    }
}

.page-option {
    padding: 0 0 0.6em 0;

    &:last-of-type {
        border: 0;
        padding-bottom: 0;
    }

    .btn {
        padding: 0;
        color: $cl-primary-1;
        text-decoration: none;
        font-size: 1.3rem;
        font-weight: bold;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $cl-primary-2;
        }

        &:focus {
            text-decoration: underline;
        }
    }

    .btn-abbr {
        display:none; //Don't show unless needed
    }
}

/*.page-option--share {
    margin-top: 2.8em;
}*/

.option--added {
    //opacity: 0.6;
}
