
// =================================
// (CL) Color settings Global
// =================================

// # START: Corporate colors #

$vf-red: #E60000;
$z-orange: #F48C00;

$dark-1: #222222;
$dark-2: #373737;

$grey-1: #575756;
$grey-2: #A8A8A7;
$grey-3: #DADADA;

$vz-orange: #F85F00;
$vz-yellow: #FFAF00;


// Primary colors
$cl-primary-1: $vf-red;
$cl-primary-2: $z-orange;
$cl-primary-3: $grey-2;

$text: rgb(87, 87, 86);


// # END: Corporate colors #

// =================================
// Color settings 'Backgrounds'
// =================================
$cl-bg-body: #fff;
$cl-bg-main: #fff;
$cl-bg-main-header: none;
$cl-bg-footer: $grey-1;
$cl-bg-socialmedia: transparent;
$cl-bg-socialmedia-hover: $cl-primary-2;

// Error colors
$cl-state-success:   #008f70;
$cl-state-warning:   #EDC100;
$cl-state-error:     #ad0101;

// Color settings 'Homepage'
$cl-bg-columns: #fff;
$cl-bg-oddeven: #f4f4f4;


// =================================
// Color settings 'States'
// =================================
$cl-state-success:   #008f70;
$cl-state-warning:   #EDC100;
$cl-state-error:     #ad0101;


// =================================
// Color settings 'Text'
// =================================

// Color 'Body'
$cl-body: $text;

// Color 'Footer'
$cl-footer: #fff;

// Color 'Breadcrumbs'
$cl-breadcrumbs: $cl-body;

// Color 'Article'
$cl-article: inherit;

// Color 'Headings'
$cl-headings: $text;


// Colors individual headings
$cl-heading-1: $cl-headings;
$cl-heading-2: $cl-headings;
$cl-heading-3: $cl-headings;
$cl-heading-4: $cl-headings;
$cl-heading-5: $cl-headings;
$cl-heading-6: $cl-headings;

// Color 'Introduction Text'
$cl-introduction: inherit;

// Color 'Blockquote'
$cl-blockquote: $cl-primary-1;

// Color 'Buttons'
$cl-buttons: inherit;

// Color 'Hyperlinks'
// Hover and Focus state is 15% darker, using this color
$cl-hlink: #0E85CF;
$cl-hlink-hover: darken($cl-primary-2, 10%);

// Color 'Socialmedia buttons'
$cl-socialmedia: $cl-body;
$cl-socialmedia-hover: $cl-primary-2;


