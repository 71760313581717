@import "../helpers/_variables";

// ##!! FontAwesome: If you want to use FontAwesome, use the SVG Map version, not the font version please. !!##
// https://fontawesome.com/how-to-use/svg-sprites

// SVGForEveryBody is already used in this project for IE9/10/11 support with our own sprite maps
// Use it for FontAwesome too if possible.
// https://github.com/jonathantneal/svg4everybody


// =================================
//   Font loading
// ================================= 
// WOFF2 is loaded first (if supported) because the file size is much smaller compared to woff & ttf(largest).
// Using woff2, woff, ttf filetypes to support a wide range of browsers:
// - Android Browser 4.1+
// - Internet Explorer 9+
// - Edge 12+
// - Firefox 4+
// - Chrome 11+
// - Safari 5.1+ / iOS Safari 5.1+
// - Samsung Internet 4+
// - Chrome for Android 66+
// - Baidu 7.12+
// - Blackberry 4.4+
// - Opera 12.1+

// Sidenote: Try to limit usage of different types of font on one page, using to much (4+) increases loading speed.
// For example: Regular, Regular Italic, Bold, Bold Italic.

// Font-family name(s)
$ff-muli:       'Muli';
$ff-zillaslab:  'Zilla Slab';
$ff-lora:       'Lora';
$ff-averta:     'Averta';

// Font-Family folder name(s)
$ff-root-base:      '/Assets/dist/fonts/';
$ff-root-muli:      '#{$ff-root-base}muli';
$ff-root-zillaslab: '#{$ff-root-base}zillaslab';
$ff-root-lora:      '#{$ff-root-base}lora';
$ff-root-averta:    '#{$ff-root-base}averta';

// Font-Family file name(s)
$ff-fn-muli:        'Muli';
$ff-fn-zillaslab:   'ZillaSlab';
$ff-fn-lora:        'Lora';
$ff-fn-averta:        'Averta';



//averta (temp)

@font-face {
    font-family: $ff-averta;
    font-weight: normal;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-averta}/regular.woff2') format('woff2');
}

@font-face {
    font-family: $ff-averta;
    font-weight: 600;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-averta}/bold.woff2') format('woff2');
}

@font-face {
    font-family: $ff-averta;
    font-weight: 700;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-averta}/black.woff2') format('woff2');
}

// ------------------------
// Font-Face - Lora
// ------------------------

// # Regular
@font-face {
    font-family: $ff-lora;
    font-weight: normal;
    font-style: normal;
    font-display: $font-display; // Ignore IntelliSense warning about font-display, it exists but IntelliSense just doesn't know it.
    src: url('#{$ff-root-lora}/#{$ff-fn-lora}_Regular.woff2') format('woff2'),
         url('#{$ff-root-lora}/#{$ff-fn-lora}_Regular.woff') format('woff'),
         url('#{$ff-root-lora}/#{$ff-fn-lora}_Regular.ttf') format('truetype');
}

// ## Regular - Italic
@font-face {
    font-family: $ff-lora;
    font-weight: normal;
    font-style: italic;
    font-display: $font-display;
    src: url('#{$ff-root-lora}/#{$ff-fn-lora}_Regular_Italic.woff2') format('woff2'),
         url('#{$ff-root-lora}/#{$ff-fn-lora}_Regular_Italic.woff') format('woff'),
         url('#{$ff-root-lora}/#{$ff-fn-lora}_Regular_Italic.ttf') format('truetype');
}

// # Bold
@font-face {
    font-family: $ff-lora;
    font-weight: bold;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-lora}/#{$ff-fn-lora}_Bold.woff2') format('woff2'),
         url('#{$ff-root-lora}/#{$ff-fn-lora}_Bold.woff') format('woff'),
         url('#{$ff-root-lora}/#{$ff-fn-lora}_Bold.ttf') format('truetype');
}

// ## Bold - Italic
@font-face {
    font-family: $ff-lora;
    font-weight: bold;
    font-style: italic;
    font-display: $font-display;
    src: url('#{$ff-root-lora}/#{$ff-fn-lora}_Bold_Italic.woff2') format('woff2'),
         url('#{$ff-root-lora}/#{$ff-fn-lora}_Bold_Italic.woff') format('woff'),
         url('#{$ff-root-lora}/#{$ff-fn-lora}_Bold_Italic.ttf') format('truetype');
}

// ------------------------
// Font-Face - Zilla Slab
// ------------------------

// # Light
@font-face {
    font-family: $ff-zillaslab;
    font-weight: 300;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Light.woff2') format('woff2'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Light.woff') format('woff'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Light.ttf') format('truetype');
}

// ## Light - Italic
@font-face {
    font-family: $ff-zillaslab;
    font-weight: 300;
    font-style: italic;
    font-display: $font-display;
    src: url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Light_Italic.woff2') format('woff2'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Light_Italic.woff') format('woff'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Light_Italic.ttf') format('truetype');
}

// # Regular
@font-face {
    font-family: $ff-zillaslab;
    font-weight: normal;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Regular.woff2') format('woff2'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Regular.woff') format('woff'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Regular.ttf') format('truetype');
}

// ## Regular - Italic
@font-face {
    font-family: $ff-zillaslab;
    font-weight: normal;
    font-style: italic;
    font-display: $font-display;
    src: url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Regular_Italic.woff2') format('woff2'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Regular_Italic.woff') format('woff'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Regular_Italic.ttf') format('truetype');
}

// # Medium
@font-face {
    font-family: $ff-zillaslab;
    font-weight: 500;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Medium.woff2') format('woff2'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Medium.woff') format('woff'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Medium.ttf') format('truetype');
}

// ## Medium - Italic
@font-face {
    font-family: $ff-zillaslab;
    font-weight: 500;
    font-style: italic;
    font-display: $font-display;
    src: url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Medium_Italic.woff2') format('woff2'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Medium_Italic.woff') format('woff'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Medium_Italic.ttf') format('truetype');
}

// # SemiBold
@font-face {
    font-family: $ff-zillaslab;
    font-weight: 600;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_SemiBold.woff2') format('woff2'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_SemiBold.woff') format('woff'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_SemiBold.ttf') format('truetype');
}

// ## SemiBold - Italic
@font-face {
    font-family: $ff-zillaslab;
    font-weight: 600;
    font-style: italic;
    font-display: $font-display;
    src: url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_SemiBold_Italic.woff2') format('woff2'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_SemiBold_Italic.woff') format('woff'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_SemiBold_Italic.ttf') format('truetype');
}


// # Bold
@font-face {
    font-family: $ff-zillaslab;
    font-weight: bold;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Bold.woff2') format('woff2'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Bold.woff') format('woff'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Bold.ttf') format('truetype');
}

// ## Bold - Italic
@font-face {
    font-family: $ff-zillaslab;
    font-weight: bold;
    font-style: italic;
    font-display: $font-display;
    src: url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Bold_Italic.woff2') format('woff2'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Bold_Italic.woff') format('woff'),
         url('#{$ff-root-zillaslab}/#{$ff-fn-zillaslab}_Bold_Italic.ttf') format('truetype');
}



// ------------------------
// Font-Face - Muli
// ------------------------

// # Light
@font-face {
    font-family: $ff-muli;
    font-weight: 300;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-muli}/#{$ff-fn-muli}_Light.woff2') format('woff2'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Light.woff') format('woff'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Light.ttf') format('truetype');
}

// ## Light - Italic
@font-face {
    font-family: $ff-muli;
    font-weight: 300;
    font-style: italic;
    font-display: $font-display;
    src: url('#{$ff-root-muli}/#{$ff-fn-muli}_Light_Italic.woff2') format('woff2'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Light_Italic.woff') format('woff'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Light_Italic.ttf') format('truetype');
}

// # Regular
@font-face {
    font-family: $ff-muli;
    font-weight: normal;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-muli}/#{$ff-fn-muli}_Regular.woff2') format('woff2'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Regular.woff') format('woff'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Regular.ttf') format('truetype');
}

// ## Regular - Italic
@font-face {
    font-family: $ff-muli;
    font-weight: normal;
    font-style: italic;
    font-display: $font-display;
    src: url('#{$ff-root-muli}/#{$ff-fn-muli}_Regular_Italic.woff2') format('woff2'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Regular_Italic.woff') format('woff'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Regular_Italic.ttf') format('truetype');
}

// # SemiBold
@font-face {
    font-family: $ff-muli;
    font-weight: 600;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-muli}/#{$ff-fn-muli}_SemiBold.woff2') format('woff2'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_SemiBold.woff') format('woff'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_SemiBold.ttf') format('truetype');
}

// ## SemiBold - Italic
@font-face {
    font-family: $ff-muli;
    font-weight: 600;
    font-style: italic;
    font-display: $font-display;
    src: url('#{$ff-root-muli}/#{$ff-fn-muli}_SemiBold_Italic.woff2') format('woff2'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_SemiBold_Italic.woff') format('woff'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_SemiBold_Italic.ttf') format('truetype');
}

// # Bold
@font-face {
    font-family: $ff-muli;
    font-weight: bold;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-muli}/#{$ff-fn-muli}_Bold.woff2') format('woff2'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Bold.woff') format('woff'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Bold.ttf') format('truetype');
}

// ## Bold - Italic
@font-face {
    font-family: $ff-muli;
    font-weight: bold;
    font-style: italic;
    font-display: $font-display;
    src: url('#{$ff-root-muli}/#{$ff-fn-muli}_Bold_Italic.woff2') format('woff2'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Bold_Italic.woff') format('woff'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Bold_Italic.ttf') format('truetype');
}

// # Black
@font-face {
    font-family: $ff-muli;
    font-weight: 900;
    font-style: normal;
    font-display: $font-display;
    src: url('#{$ff-root-muli}/#{$ff-fn-muli}_Black.woff2') format('woff2'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Black.woff') format('woff'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Black.ttf') format('truetype');
}

// ## Black - Italic
@font-face {
    font-family: $ff-muli;
    font-weight: 900;
    font-style: italic;
    font-display: $font-display;
    src: url('#{$ff-root-muli}/#{$ff-fn-muli}_Black_Italic.woff2') format('woff2'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Black_Italic.woff') format('woff'),
         url('#{$ff-root-muli}/#{$ff-fn-muli}_Black_Italic.ttf') format('truetype');
}