@import "../helpers/_variables";

// Only apply margins if no FB margins are present

.container--home,
.container--article {

    > img:not([class*="fbml"]),
    > img:not([class*="FBML"]) {
        margin: 0.8em 0 1.6em 0;
    }
}

.image {
    margin-bottom: 2em;
    
    img {
        margin-bottom: 0.2em;
    }

    .caption {
        padding-left: 2rem;
        display: block;
        font-size: 75%;
        color: #9B9B9B;
    }
}

// Creates responsive video's
.f-video {
    width: 100%;
    margin: 0.5em 0 1em 0;
    position: relative;

    .article-content--home & {
        max-width: calc(100% + 20vw);
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    &::before {
        content: "";
        display: block;
        padding: 0 0 56.25% 0;
    }

    &-align {

        &--left {
            margin-right: 0.7em;
        }

        &--right {
            margin-left: 0.7em;
        }
    }

    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 30%;
        left: 0;
        transform: translateY(-50%);
    }
}