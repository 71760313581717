@import "../helpers/_variables.scss";

.js-fadeIn {
    opacity: 0;
    transform: translateY(10%);
    transition: all 1.5s ease;

    &.fadeIn--active {
        opacity: 1;
        transform: translateY(0%);
    }
}