@import "../helpers/_variables";

// Columns
$f-columns-grid: 12;
$f-columns-gutter: 3vw;
$f-columns-article-width: 50%;

.f-row,
.row-dynamic,
.f-block-columns {
    @include fb-make-row($f-columns-gutter);
    justify-content: center;

    .s_no-flexbox & {
        @include clear();
    }
}

.f-row {
    align-items: stretch;
    //justify-content: flex-start; // Better support for IE11 enable this and disable 'center' positioning
    justify-content: center;
}

.row-dynamic,
.f-block-columns {
    align-items: flex-start;
}



.f-col,
.col-dyn,
.f-block-column {
    min-width: 22rem; // Minimum when container is too small for content
    margin: 0 0 $f-columns-gutter 0;
    @include fb-make-col-ready($f-columns-gutter);
    
     .s_no-flexbox & {
        float: left;
    }

    h2, 
    h3, 
    h4, 
    h5, 
    h6,
    a,
    img,
    .f-btn {
        flex: 0 0 auto;
    }

    // IE10/IE11 (2)Fixes
    // Max width declarations in % to prevent child overflowing parent.
    // Flex shrink 0 prevents text collapsing on itself in IE11
     p {
        min-height: 1px;
        max-width: 100%;
        width: 100%;
        flex: 1 0 auto;
    }

     
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.f-col {
    max-width: 50%;
    min-width: 26rem;
    width: 100%;
    //flex-grow: 0;
    @include fb-make-cols($f-columns-grid);
}

.f-block-column {
    max-width: 44rem;
    width: $f-columns-article-width;
}