@import "../helpers/_variables";

// =================================
//   Media Query Breakpoints - Non-mobile first
// ================================= 

// Large devices, Large desktops
@media only screen and (max-width : $screen-nm-min){
    @import "_large.scss";

    //variables
    $header-height: 65px;
}

// Medium devices, Desktops
@media only screen and (max-width : $screen-md-min){
    @import "_medium.scss";

    //variables
    $header-height: 25px;
}

// Small devices, Tablets
@media only screen and (max-width : $screen-sm-min){
    @import "_small.scss";
}

// Extra small devices, Phones
@media only screen and (max-width : $screen-xs-min){
    @import "_extrasmall.scss";
}