@import "../helpers/_variables";

// =================================
//   Specific homepage styling
// =================================


.content-home-wrapper {
    color: $cl-article;
    font-size: $fs-article;
    font-weight: $fw-article;
    font-family: $set-ff-article;
    line-height: $lh-article;
    //padding: 3rem 2rem;
    padding: 5rem 0;
    background-color: #FFF;
    //background-color: #F4F4F4;

    &:nth-child(1) {
        //padding: 0;
    }

    &:nth-child(even) {
        //background-color: $cl-bg-oddeven;
    }

    h2 {
        font-size: 4rem;
    }

    h3 {
        font-size: 3.2rem;

    }

    p {
        //margin: 1em 0;
    }

    .f-btn {
        font-size: 1.6rem;
    }

}



.content-seperator {
    min-height: 1px; // Prevents half pixel rendering on some browsers
    width: 100%;
    height: 0.1rem;
    margin: 4rem 0;
    display: block;
    background-color: $cl-primary-2;
}

.content-block {
    max-width: 100%;
    height: 100%;
    padding: 0 2rem;
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 1 100%;
    background-color: $cl-bg-columns;
    animation: fade-in-small 1s ease 1 normal;
    animation-fill-mode: both;
    animation-play-state: paused;
    transform: translateY(0%); // Fix for some browsers offsetting txt wrong

    &.in-view--true,
    .in-view--true & {
        animation-play-state: running;
    }

    > *:first-child:not(img) {
        margin-top: 2rem;
    }

    > *:last-child:not(img) {
        margin-bottom: 2rem;
    }

    img {
        max-width: none;
        width: calc(100% + 4rem);
        margin: 0 -2rem;
        height: auto; // In case a hard height is defined
    }

    p {
        margin: 1em 0;
        flex: 0 0 auto;

        // Only grow when only- or last-item.
        // Otherwise spacing between paragraphs is off with multiple paragraphs
        &:last-of-type {
            flex: 1 0 auto;
        }
    }
}