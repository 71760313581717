@import "../helpers/_variables";
 

// =================================
//   only screen and (xxx-width: 480px)
// =================================

$fs-heading-1: round(($fs-heading-1 / 1.5) * 100) / 100;
$fs-heading-2: round(($fs-heading-2 / 1.4) * 100) / 100;
$fs-heading-3: round(($fs-heading-3 / 1.2) * 100) / 100;


h1,
.h1 {
    font-size: $fs-heading-1;
}

h2,
.h2 {
    font-size: $fs-heading-2;
}

h3,
.h3 {
    font-size: $fs-heading-3;
}


/*.nav_logo {
    margin: 0;
    padding: 0;
}

.nav_logo-corp {
    max-width: 8rem;
    max-height: 8rem;
}*/

.nav-list_url {
    font-size: 1.8rem;
    padding: 1rem 0;
}


.main-content {
    margin-top: 4.8rem; // Space for sticky nav

    &.main--home,
    &.has-teaser--true {
        margin-top: 4.8rem;
    }
}

.nav .btn-menu span {
    display: none;

    &.css-icon-menu {
        display: block;

        span {
            display: block;
        }
    }
}

.main-content-wrapper,
.article-content-container {
    display: block;
    width: 100%;
}


.container--article {
    flex-wrap: wrap;
}

.article-intro {
    font-size: 110%;
}

.container--home {
    padding: 0 10vw;

    > p {
        font-size: 1.5rem;
    }

    > *:nth-child(1)[src*="images"],
    > *:nth-child(1)[src*="Images"],
    > .f-carousel,
    > .f-video {
        max-width: none;
        width: calc(100% + 16vw);
        //width: calc(100% + 20vw);
        margin-left: -10vw;
        margin-right: -10vw;
    }
}

.prevnext {
    display: block;
}

.prevnext_next {
    border: none;
}

.f-carousel {

    h1 {
        font-size: 1.8rem;

        .ve-homeview & {
            font-size: 1.8rem;
        }
    }

    .btn {
        font-size: 1.4rem;
    }
}

.flickity-prev-next-button {
    width: 2.8rem;
    height: 3rem;
}