// =================================
//   Breakpoint settings
// ================================= 
$screen-xs-min:              480px;  // Extra small screen / phone
$screen-sm-min:              768px;  // Small screen       / tablet
$screen-md-min:              850px;  // Medium screen      / desktop medium
$screen-nm-min:              1100px;  // Medium screen      / desktop
$screen-lg-min:              1300px; // Large screen       / large desktop

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-nm-min - 1);
$screen-nm-max:              ($screen-lg-min - 1);
$screen-lg-max:              (1600px - 1);
