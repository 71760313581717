@import "../helpers/_variables";

// =================================
// SVG Icons
// =================================

svg {
    fill: currentColor; // Inherit declared color from parent, perfect for icons with dynamic colors
    line-height: 1;
    text-rendering: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.svg-icon {
    max-width: 100%;
    width: 2rem; // In case a new SVG icon doesn't have any sizes defined, prevents extremely large SVGs by accident
    height: 2rem;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    color: currentColor;
}

.svg-icon-add,
.svg-icon-remove {
    width: 2.2rem;
    height: 2.3rem;
}

.svg-icon-download,
.svg-icon-upload {
    width: 2.2rem;
    height: 2.2rem;
}

.svg-icon-search {
    width: 1.6rem;
    height: 1.6rem;
    fill: $dark-2;
}

.svg-icon-arrow-l,
.svg-icon-arrow-r,
.svg-icon-arrow-thin-l,
.svg-icon-arrow-thin-r {
    width: 1.3rem;
    height: 2.2rem;
}

.svg-icon-abbr-hidden,
.svg-icon-abbr-visible {
    width: 2.2rem;
    height: 1.9rem;
}

.svg-icon-trash {
    width: 2rem;
    height: 2.2rem;
}

.svg-icon-reset {
    width: 2.2rem;
    height: 2.2rem;
}

.svg-icon-close {
    width: 2.2rem;
    height: 2.2rem;
}

.svg-icon-accessiblity {
    width: 1.8rem;
    height: 2.2rem;
}

.svg-icon-file {
    width: 2.1rem;
    height: 2.8rem;
}

.svg-icon-print {
    width: 2.2rem;
    height: 2.2rem;
}

.svg-icon-quote-l,
.svg-icon-quote-r {
    width: 2.5rem;
    height: 2.2rem;
}

.svg-icon-tags {
    width: 2.7rem;
    height: 2.2rem;
}

.svg-icon-tag {
    width: 2.2rem;
    height: 2.2rem;
}

.svg-icon-tasks {
    width: 2.6rem;
    height: 2.2rem;
}

.svg-icon-check {
    width: 2.2rem;
    height: 1.4rem;
}

.svg-icon-check-circle {
    width: 2.2rem;
    height: 2.2rem;
}

.svg-icon-sort {
    width: 2.9rem;
    height: 2.2rem;
}

.svg-icon-touch {
    width: 1.6rem;
    height: 2.2rem;
}

.svg-icon-image {
    width: 3.8rem;
    height: 2.2rem;
}


.svg-logo--ziggo {
    width: 101px;
}


// Socialmedia
.svg-icon-twitter {
    width: 2.4rem;
    height: 2.0rem;
}

.svg-icon-facebook {
    width: 1.2rem;
    height: 2.2rem;
}

.svg-icon-linkedin {
    width: 2.2rem;
    height: 2.2rem;
}

.svg-icon-youtube {
    width: 3.1rem;
    height: 2.2rem;
}

.svg-icon-pinterest {
    width: 1.8rem;
    height: 2.2rem;
}

.svg-icon-gplus {
    width: 3.4rem;
    height: 2.2rem;
}

.svg-icon-vimeo {
    width: 2.4rem;
    height: 2.2rem;
}

.svg-icon-reddit {
    width: 2.3rem;
    height: 2.2rem;
}

.svg-icon-whatsapp {
    width: 2.2rem;
    height: 2.2rem;
}

.svg-icon-tumblr {
    width: 1.4rem;
    height: 2.2rem;
}

.svg-icon-snapchat {
    width: 2.4rem;
    height: 2.4rem;
}

.svg-icon-instagram {
    width: 2.5rem;
    height: 2.5rem;
}


// Transforms

.ro-45 {
    transform: rotateZ(45deg);
}

.ro-45-ccw {
    transform: rotateZ(-45deg);
}

.ro-90 {
    transform: rotateZ(90deg);
}

.ro-90-ccw {
    transform: rotateZ(-90deg);
}

.ro-180 {
    transform: rotateZ(90deg);
}

.ro-180-ccw {
    transform: rotateZ(-180deg);
}

