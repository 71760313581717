@import "../helpers/_variables";

// =================================
//   Base
// =================================
b,
strong {
    font-weight: inherit; // Safari fix
    font-weight: bold;
}

i,
em,
.italic {
    font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $cl-headings;
    font-family: $set-ff-headings;
    font-weight: $fw-headings;
    line-height: $lh-headings;
    font-feature-settings: $ffs-headings;
    cursor: default;
    //text-shadow: rgba(0, 0, 0, 0.5) 0px 10px 25px, rgba(0, 0, 0, 0.5) 0px 1px 2px;

    a &,
    button & {
        cursor: pointer;
    }
}

// Font alignment to bodytext
h1,
h2,
h3 {
    margin-left: -0.04em;
}

// Adding break-word to make sure layout isn't broken by large titles.
h1,
h2 {
    @include break-word();
}

h1 {
    margin: $ws-heading-1;
    color: $cl-heading-1;
    font-size: $fs-heading-1;
    font-weight: $fw-heading-1;
    line-height: $lh-heading-1;
}

h2 {
    margin: $ws-heading-2;
    color: $cl-heading-2;
    font-size: $fs-heading-2;
    font-weight: $fw-heading-2;
    line-height: $lh-heading-2;
}

h3 {
    margin: $ws-heading-3;
    color: $cl-heading-3;
    font-size: $fs-heading-3;
    font-weight: $fw-heading-3;
    line-height: $lh-heading-3;
}

h4 {
    margin: $ws-heading-4;
    color: $cl-heading-4;
    font-size: $fs-heading-4;
    font-weight: $fw-heading-4;
    line-height: $lh-heading-4;
}

h5 {
    margin: $ws-heading-5;
    color: $cl-heading-5;
    font-size: $fs-heading-5;
    font-weight: $fw-heading-5;
    line-height: $lh-heading-5;
}

h6 {
    margin: $ws-heading-6;
    color: $cl-heading-6;
    font-size: $fs-heading-6;
    font-weight: $fw-heading-6;
    line-height: $lh-heading-6;
}


p {
    margin: $ws-paragraph;
    cursor: default;

    a &,
    button & {
        cursor: pointer;
    }

    &.f-area-list-ordered-after,
    &.f-area-list-unordered-after {
        margin-bottom: 0.1em;
    }
}

a {
    color: $cl-hlink;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;

    &:active {
        //color: darken($cl-hlink, 15%);
    }

    &:focus,
    &:hover {
        //color: darken($cl-hlink, 15%);
        //color: $cl-hlink-hover;
    }

    &:focus {
        //text-decoration: underline;
    }
}

ul,
ol {
    margin: $ws-lists;
    padding: 0 0 0 1.2em;

    p {
        margin: 0 0 0.3em 0;
        padding: 0;
    }
}


li {
    margin: 0.2em 0 0.2em 0;

    @include rmMarginFirstLast();
}

blockquote {
    margin: $ws-blockquote;
    padding: 1.8em 2em;
    position: relative;
    color: $cl-blockquote;
    font-size: $fs-blockquote;
    font-weight: $fw-blockquote;
    font-family: $set-ff-blockquote;
    line-height: $lh-blockquote;
    background-color: rgba($cl-primary-1, 0.05);
    overflow: hidden;

    @include rmMarginFirstLast();

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    p {
        color: inherit;
    }

    .caption {
        margin: 0.1em 0 0 0;
        font-style: italic;
        font-size: $fs-blockquote-caption;
        font-weight: $fw-blockquote-caption;

        &::before {
            margin: 0 0.6em 0 0;
            content:"\2014";
            display: inline-block;
            vertical-align: top;
        }
    }
}

.blockquote-description {
    font-size: 75%; // At 16px base becomes 12px
}

.fbml-nested-list {
    list-style-type: none;
}

abbr {
  border-bottom: none;
  text-decoration: none;
}

abbr[title] {
  border-bottom: 0.1rem dotted currentColor;
  text-decoration: none;
}

mark {
  border: none;
  //color: #FFF;
  background-color: rgba($cl-primary-1, 0.15);
}

small {
  font-size: 80%;
}

// Remove the default vertical scrollbar in IE.
textarea {
  overflow: auto;
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

span {
    cursor: default;

    a &,
    button & {
        cursor: inherit;
    }
}