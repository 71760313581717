@import "../helpers/_variables";

.nav {
    width: 100%;
    margin: 0 auto 0 auto;
    position: relative;
    z-index: 55;
    flex: 0 0 auto;
    background: #FFF;
    //box-shadow: 0 0 7px rgba(0,0,0,.2);
    &:after {
        content: "";
        display: block;
        height: 5px;
        max-width: $screen-nm-min;
        width: 100%;
        margin: 0 auto;
        background: linear-gradient(90deg, $vf-red, $z-orange);
    }

    .artnav--open & {
        z-index: 1;
    }

    .btn-menu {
        display: none;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 1.2rem;
        text-transform: uppercase;
    }
}

.container--nav {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.25rem 0 1rem;
}

.nav_logo {
    padding: 0;
    margin: .5rem 0 0;
    position: relative;
    z-index: 99;

    &:after {
        //content: "";
        height: 2px;
        width: 50px;
        background: #ececec;
        position: absolute;
        bottom: -2.6rem;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
    }

    svg {
        color: $cl-primary-1;
        height: 35px;
    }

    .svg-logo--vodafone {
        padding: 3px 0;
        width: 165px;
        margin-right: 5px;
    }
}

.nav_logo-url {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    position: relative;
    top: 2px;

    &:active,
    &:focus {
        text-decoration: none;
    }

    img {
        margin: 0;
        border: 0;
        position: relative;
        z-index: 2;
    }
}

.nav_logo-corp {
    max-width: 16rem;
    max-height: 16rem;
    //width: 100%;
    width: 12.6rem;
    display: block;
    position: relative;
    z-index: 5;
}

.nav_logo-slogan {
    font-size: 1.2rem;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 6.2rem;
    z-index: 2;
}



.nav_meta {
    flex: 1 1 100%;
    width: 100%;
    margin: 0 0 2rem 0;
    position: relative;
    font-size: 1.2rem;
    text-align: right;

    a {
        color: inherit;
    }
}

.nav_mobile_wrapper {
    padding: 1.5rem 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1 auto;
}

.nav_menu {
    flex: 0 0 auto;
}

.nav-list {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    font-size: $fs-navigation;
    font-weight: $fw-navigation;


    a,
    .btn {
        color: inherit;
        transition: color ease 0.15s;
    }
}


.nav-list_item {
    margin: 0 2rem 0 0;
    display: inline-block;

    &.has-count {
        //margin-right: 37px;
    }

    &:last-of-type {
        margin-right: 0;

        a {
            padding-right: 0;
            margin-right: 0;
        }
    }

    &-btn {
        padding: 0;
    }
}

.nav-list_url {
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
    //text-transform: uppercase;

    color: #fff;

    .selected &,
    &:hover,
    &:focus {
        color: $cl-primary-1;
        outline-width: 0;
    }

    &:focus {
        text-decoration: underline;
    }

    .selected & {
        //cursor: default;
    }

    &:focus {
        text-decoration: underline;
    }
}

// Article navigation containers/options
.nav-content {
    padding: 2rem 2rem 1rem 2rem;
    position: relative;
    z-index: 1;
}

.nav-article {

}

.nav-switcher {
    display: flex;
    justify-content: space-around;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.02em;

    a {
        flex-grow: 1;
        line-height: 50px;
        font-weight: bold;
        text-align: center;
    }

    .active {
        opacity: 1;
        background: #fff;
    }
}

.sub-nav {
    position: absolute;
    width: 100%;
    margin-top: 5px;
    color: #fff;

    .container {
        z-index: 3;
        position: relative;
    }

    ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;

        li {
            padding: 1rem 0 1.8rem;
            margin: 0 2rem;
            font-weight: 600;
            cursor: pointer;
            font-size: 1.5rem;

            &:hover {
                color: $vz-orange;
            }
        }
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $dark-1;
        opacity: .7;
    }
}