@import "../helpers/_variables";

.article-content {
    padding: 1.4rem 0 2rem 0;
    color: $cl-article;
    font-size: $fs-article;
    font-weight: $fw-article;
    font-family: $set-ff-article;
    line-height: $lh-article;

    > h1:first-of-type {
        margin-top: 0;
    }
}

.intro,
.article-intro {
    margin: $ws-introduction;
    color: $cl-introduction;
    font-family: $set-ff-introduction;
    font-weight: $fw-introduction;
    line-height: $lh-introduction;
    font-size: $fs-introduction;
}

// Class set from editor
.no-margin-bottom {
    margin-bottom: 0 !important;
}


.article-content--home {
    width: 100%;
    margin: 0 auto;
    padding: 5rem 10vw 2rem 10vw;
    flex: 1 1 auto;
    border: none;
    background: none;

    img,
    .f-carousel {
        max-width: calc(100% + 20vw);
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .f-carousel {
        width: calc(100% + 20vw);
    }
}

.article-content--downloads {
    width: 100%;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}


.article-chart-container {
    margin: 1rem 0 0.4rem 0;
}

// ============================================================================================
//   Usage of tabular layout so indicator can shrink/grow when needed and also keeping other 'cells' the same size.
// ============================================================================================
.note-list {
    display: table;
    padding: 0 0 0 0.2em;
    overflow: hidden;
    font-size: $fs-footnotes;

    li {
        width: 100%;
        position: relative;
        display: table-row;
        clear: both;
    }

    .note-indicator,
    .note-seperato,
    .note-content {
        padding: 0.2em;
        display: table-cell;
        vertical-align: top;
    }

    .note-indicator {
        min-width: 20px;
        padding-left: 0;
        padding-right: 0.3em;
    }
}
