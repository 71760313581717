@import "../helpers/_variables";

.breadcrumbs {
    width: 100%;
    margin: 0;
    padding: 2rem 0;
    font-size: $fs-breadcrumbs;
    display: none;

    .main--page & {
        display: none;
    }
}

.breadcrumb {
    margin: 0 0 0 0.8rem;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    color: $cl-breadcrumbs;

    &::before {
        content: "/";
        font-size: 1rem;
        margin: 0 0.8rem 0 0;
        position: relative;
    }

    &:focus,
    &:hover {
        text-decoration: none;

        span {
            text-decoration: underline;
        }
    }


    &:first-child {
        margin: 0;

        &::before {
            display: none;
        }
    }

    &:last-of-type {
        font-weight: 400;
        color: darken($cl-breadcrumbs, 15%);
    }
}

.breadcrumb-seperator {
    margin: 0.2rem 0 0 0;
    display: inline-block;
    vertical-align: top;
    font-size: 1.2rem;
}
